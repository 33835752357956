function CommentsModal(props) {
  let commentValue = "";

  let postComment = (event) => {
    event.preventDefault();
    const commentData = {
      text: commentValue,
    };
    props.helper
      .request(
        "v1",
        "file/" + props.selectedTask.uuid + "/comment",
        commentData,
        "post"
      )
      .then((response) => {
        if (response.status === "success") {
          props.updateCommentsModalFlag("commentsSuccess");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let handleChange = (event) => {
    event.preventDefault();
    commentValue = event.target.value;
  };

  var React = oxziongui.React;
  return React.createElement(
    "div",
    { className: "modal-inner-container" },
    React.createElement(
      "div",
      { className: "modal-header" },
      React.createElement("i", { className: "fa-solid fa-messages" }),
      React.createElement(
        "div",
        { className: "modal-header-title" },
        "Leave a comment"
      ),
      React.createElement("i", {
        className: "fa-solid fa-xmark modal-close-icon",
        onClick: () => props.closeModal(),
      })
    ),
    props.flag === "comments"
      ? React.createElement(
          "div",
          { className: "comment-container" },
          React.createElement(
            "div",
            { className: "task-name-container" },
            React.createElement(
              "div",
              { className: "task-name" },
              props.selectedTask.name
            ),
            React.createElement(
              "div",
              { className: "button-container" },
              React.createElement(
                "button",
                {
                  type: "button",
                  className: "button",
                  onClick: (event) => postComment(event),
                },
                "SEND"
              ),
              React.createElement(
                "button",
                {
                  type: "button",
                  className: "button",
                  onClick: () => props.closeModal(),
                },
                "CANCEL"
              )
            )
          ),
          React.createElement(
            "div",
            { className: "comment-box-container" },
            React.createElement(
              "div",
              { className: "comment-label" },
              "Leave a comment:"
            ),
            React.createElement(
              "div",
              { className: "comment-box" },
              React.createElement("textarea", {
                className: "text-area",
                rows: "4",
                cols: "50",
                id: "comment-text-area",
                name: "textarea",
                onChange: (event) => handleChange(event),
              })
            )
          )
        )
      : props.flag === "commentsSuccess"
      ? React.createElement(
          "div",
          { className: "modal-content-container" },
          React.createElement(
            "div",
            { className: "success-msg-container" },
            React.createElement(
              "div",
              { className: "success-msg" },
              "Comment posted successfully..!"
            )
          ),
          React.createElement(
            "div",
            { className: "button-container" },
            React.createElement(
              "button",
              {
                type: "button",
                className: "button",
                onClick: () => props.closeModal(),
              },
              "CLOSE"
            )
          )
        )
      : null
  );
}

export default CommentsModal;
