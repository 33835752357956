import { ServiceProvider } from "@osjs/common";
import Swal from "sweetalert2";
import { createBrowserHistory } from "history";

export class BosAdapter extends ServiceProvider {
  constructor(core, options = {}) {
    super(core, options || {});
    this.core = core;
    this.metadata = [];
  }

  providers() {
    return ["oxzion/core"];
  }

  async init() {
    this.core.on("osjs/core:started", () => {
      document.body.classList.add("osjs-root");
      var queryString = window.location.search.substr(1);
      if (queryString) {
        var queryObj = queryString
          .split("&")
          .reduce(function (prev, curr, i, arr) {
            var p = curr.split("=");
            prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
            return prev;
          }, {});
        var appName = queryObj.app;
        var userDetails = this.core.make("oxzion/profile").get();
        if (!(appName in userDetails.key.blackListedApps)) {
          this.core
            .request(this.core.config("packages.manifest"), {}, "json")
            .then((metadata) => {
              setTimeout(() => {
                this.addPackages(metadata);
                this.launch(queryObj);
                const collection = window.parent.document.getElementsByClassName("osjs-panel");
                if (collection && collection.length !== 0) {
                  const array = [...collection[0].children];
                  const windowsElements = array?.filter(each => {
                    return each.dataset.name === 'windows';
                  });
                  if (windowsElements?.length !== 0) {
                    let appTitle = this.fetchAppJson(metadata, appName);
                    const arr = [...windowsElements[0].children];
                    arr?.map((each) => {
                      if (each.innerText.includes(appTitle)) {
                        each.click();
                      }
                    })
                  }
                }
              }, 3000);
            })
            .catch((error) => console.error(error));
        }
        else {
          const history = createBrowserHistory();
          history.push("/");
          this.core
            .request(this.core.config("packages.manifest"), {}, "json")
            .then((metadata) => {
              let appTitle = this.fetchAppJson(metadata, appName);
              Swal.fire({
                icon: "error",
                title: `You do not have access to ${appTitle} App`,
                position: "center",
                confirmButtonText: "OK",
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: "#275362",
              });
            })
        }
      }
    });

    this.core.on("osjs/core:started", () => {
      var userDetails = this.core.make("oxzion/profile").get();
      var myDate = new Date();
      var hrs = myDate.getHours();
      var greet;
      if (hrs < 12) greet = "Good Morning";
      else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
      else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
      //console.log(this.core.make('oxzion/profile').get().UserInfo();
      if (userDetails["key"]["preferences"]["Greetingmessage"] == "true") {
        this.core.make("osjs/notification", {
          timeout: 10000,
          icon: userDetails["key"]["icon"],
          title: "Welcome to EOS!",
          message:
            "Hello and " + greet + " " + userDetails["key"]["firstname"] + "!",
        });
      }
    });

    this.core.on("oxzion/application:launch", (params) => {
      if (params.app) {
        this.core
          .make("osjs/packages")
          .launch(
            params.app,
            params.args ? params.args : {},
            params.options ? params.options : {}
          );
      }
    });
  }

  fetchAppJson(metadata, appName) {
    const appDetails = metadata.filter((each) => each.name === appName);
    const appTitle = appDetails?.length !== 0 ? appDetails[0].title?.en_EN : '';
    return appTitle ?? "";
  }

  addPackages(list) {
    if (list instanceof Array) {
      const append = list.map((iter) =>
        Object.assign(
          {
            type: "application",
          },
          iter
        )
      );
      this.metadata = [...this.metadata, ...append];
    }
  }

  launch(app) {
    var found = this.metadata.find((pkg) => pkg.name === app.app);
    if (found != "undefined" && found != undefined) {
      var appName = app.app;
      var params = app;
      delete params.app;
      if (app.params) {
        params = app.params;
      }
      if (app.args) {
        params = app.args;
      }
      this.core
        .make("osjs/packages")
        .launch(appName, params, app.options ? app.options : {});
    }
  }
}
