// import { KendoReactButtons } from "oxziongui";
function Modal(props) {
  const snoozeFunctionalities = [
    {
      label: "Extend",
      icon: "fa-solid fa-calendar-days",
      style: { float: "left", padding: "20px" },
    },
    {
      label: "Snooze",
      icon: "fa-solid fa-alarm-snooze",
      style: { float: "right", padding: "20px" },
    },
  ];

  const unsnoozeFunctionalities = [
    {
      label: "Unsnooze",
      icon: "fa-regular fa-hourglass-clock",
      style: { float: "right", padding: "20px" },
    },
  ];

  const snoozeSuccess = [
    {
      label:
        props.flag === "snoozeSuccess"
          ? "Snoozed Successfully"
          : "Unsnoozed Successfully",
      icon:
        props.flag === "snoozeSuccess"
          ? "fa-solid fa-alarm-snooze"
          : "fa-regular fa-hourglass-clock",
      style: { float: "left", padding: "20px 0px" },
      subLabel: "show all",
    },
  ];

  const extendNotification = (event) => {
    event.preventDefault();
    const endDate = {
      end_date: props.selectedDate,
    };
    props.helper
      .request(
        "v1",
        "app/" +
          props.selectedTask.app_uuid +
          "/file/crud/" +
          props.selectedTask.uuid,
        endDate,
        "put"
      )
      .then((response) => {
        if (response.status === "success") {
          const commentData = {
            text: props.commentsValue,
          };
          props.helper
            .request(
              "v1",
              "file/" + props.selectedTask.uuid + "/comment",
              commentData,
              "post"
            )
            .then((response) => {
              props.updateSnoozeModalFlag("extendSuccess");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  };

  const callSnoozeAPI = (event, each) => {
    event.preventDefault();
    if (each.label === "Snooze" || each.label === "Unsnooze") {
      props.helper
        .request(
          "v1",
          `/file/${props.selectedTask.uuid}/snooze`,
          { snooze: props.flag === "snooze" ? 1 : 0 },
          "post"
        )
        .then((response) => {
          if (response.status === "success") {
            props.flag === "snooze"
              ? props.updateSnoozeModalFlag("snoozeSuccess")
              : props.updateSnoozeModalFlag("unsnoozeSuccess");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (each.label === "Extend") {
      props.updateSnoozeModalFlag("extend");
    }
  };

  const showAllSnoozedTask = (event) => {
    event.preventDefault();
    props.launchNotificationApp();
  };

  let modalData =
    props.flag === "snooze"
      ? snoozeFunctionalities
      : props.flag === "unsnooze"
      ? unsnoozeFunctionalities
      : snoozeSuccess;
  var React = oxziongui.React;
  var DatePicker = oxziongui.KendoReactDateInputs.DatePicker;
  var today = new Date();

  return React.createElement(
    "div",
    { className: "modal-inner-container" },
    React.createElement(
      "div",
      { className: "modal-header" },
      React.createElement("i", { className: "fa-solid fa-alarm-snooze" }),
      React.createElement(
        "div",
        { className: "modal-header-title" },
        props.flag === "extend"
          ? "Choose Extend functionality"
          : "Choose the functionality"
      ),
      React.createElement("i", {
        className: "fa-solid fa-xmark modal-close-icon",
        onClick: () => props.closeModal(),
      })
    ),
    React.createElement(
      "div",
      {
        className: "modal-content-container",
      },
      props.flag === "extendSuccess"
        ? React.createElement(
            "div",
            { className: "modal-content" },
            React.createElement(
              "div",
              { className: "success-msg-container" },
              React.createElement(
                "div",
                { className: "success-msg" },
                "You will be reminded on selected date ",
                oxziongui.Moment(props.selectedDate).format("DD-MM-YYYY")
              )
            ),
            React.createElement(
              "div",
              { className: "button-container" },
              React.createElement(
                "button",
                {
                  type: "button",
                  id: "close",
                  className: "button",
                  onClick: () => props.closeModal(),
                },
                "CLOSE"
              )
            )
          )
        : props.flag === "extend"
        ? React.createElement(
            "div",
            { className: "extend-container" },
            React.createElement(
              "div",
              { className: "extend-to-container" },
              React.createElement(
                "div",
                { className: "extend-label" },
                "Extend to:"
              ),
              React.createElement(
                "div",
                null,
                React.createElement(DatePicker, {
                  className: "date-picker",
                  format: "dd/MM/yyyy",
                  min: today,
                  defaultValue: props.selectedDate,
                  onChange: (event) => props.onDateSelect(event),
                })
              )
            ),
            React.createElement(
              "div",
              { className: "comment-box-container" },
              React.createElement(
                "div",
                { className: "extend-label" },
                "Leave a comment:"
              ),
              React.createElement(
                "div",
                { className: "comment-box" },
                React.createElement("textarea", {
                  className: "text-area",
                  rows: "4",
                  cols: "50",
                  id: "comment-text-area",
                  name: "textarea",
                  onChange: (event) => props.onCommentsHandleChange(event),
                })
              )
            ),
            React.createElement(
              "div",
              { className: "button-container" },
              React.createElement(
                "button",
                {
                  type: "button",
                  id: "send",
                  className:
                    props.commentsValue === "" ? "disabled-button" : "button",
                  disabled: props.commentsValue === "" ? true : false,
                  style: { margin: "15px 0px 0px 0px" },
                  onClick: (event) => extendNotification(event),
                },
                "SEND"
              )
            )
          )
        : React.createElement(
            "div",
            {
              className: "modal-content",
              style:
                props.flag === "snoozeSuccess" ||
                props.flag === "unsnoozeSuccess" ||
                props.flag === "unsnooze"
                  ? { display: "flex", alignItems: "center" }
                  : null,
            },
            modalData.map((each, index) =>
              React.createElement(
                "div",
                {
                  key: index,
                  className: "each-function",
                  style: each.style,
                  onClick: (event) => callSnoozeAPI(event, each),
                },
                React.createElement("i", {
                  className: each.icon,
                  style: { color: "#0057FF", fontSize: "38px" },
                }),
                React.createElement("div", { className: "label" }, each.label),
                each.subLabel &&
                  React.createElement(
                    "div",
                    {
                      className: "sub-label",
                      onClick: (event) => showAllSnoozedTask(event),
                    },
                    each.subLabel
                  )
              )
            )
          )
    )
  );
}

export default Modal;
