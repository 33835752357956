module.exports = {
    development : false,
    wrapper: {
      url: "https://triumphinstest.eoxvantage.com:9080/"
  },
  api:{
    url: "https://triumphinstest.eoxvantage.com:9080/"
  },
  ui:{
    url: "https://triumphinstest.eoxvantage.com"
  },
	desktop: {
	settings: {
	  font: 'Roboto'
	}
  },

}
